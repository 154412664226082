<template>
  <div>
    <h6 class="text-center" >
      <strong>Información importante</strong>
    </h6>
    <p>
      A partir del 1 de junio de 2020, debido a un nuevo decreto nacional, se aplican retenciones de Impuesto Sobre la
      Renta (ISR) y de Impuesto al Valor Agregado (IVA). <br>
      Serán alcanzadas por esta retención todas las ventas de productos nuevos que realicen personas físicas y
      vendedores que no tengan registrado un RFC en Ecart.
    </p>
    <p class="text-center">
      <strong>
        ¿Qué son las retenciones?
      </strong>
    </p>
    <p>
      Las retenciones son cobros impositivos sobre tus operaciones. Como somos agente de recaudación ante SAT, por ley
      debemos retener un porcentaje del dinero que cobras por tus ventas. Ese dinero retenido luego lo entregamos al
      SAT.
    </p>
    <p class="text-center">
      <strong> ¿Quiénes son alcanzados por estas retenciones?</strong>
    </p>
    <p>
      Para cumplir con la ley, debemos retener un porcentaje en cada venta de productos nuevos a los vendedores que sean
      Personas Físicas.
      Si no tienes ningún RFC cargado en nuestra plataforma, tendremos que aplicar los porcentajes máximos de retención.
      Puedes consultar cómo cargar tu RFC, cómo tramitarlo o actualizarlo en SAT <a
        href="https://www.sat.gob.mx/personas/tramites-del-rfc" target="_blank">
        aqui</a>.
    </p>
    <p class="text-center"><strong>¿Cómo se aplican las retenciones?</strong></p>
    <p> Porcentaje de retención por venta: </p>
    <table class="table table-sm table-striped text-center">
      <thead>
        <tr>
          <th></th>
          <th> SI TIENES RFC DE PERSONA FÍSICA CARGADO EN ECART</th>
          <th>SI NO TIENES RFC CARGADO EN ECART</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            IVA
          </td>
          <td>
            8%
          </td>
          <td>
            16%
          </td>
        </tr>
        <tr>
          <td>
            ISR
          </td>
          <td>
            1%
          </td>
          <td>
            20 %
          </td>
        </tr>
      </tbody>
    </table>
    <hr>
  </div>
</template>

<script>
  export default {
  }
</script>

<style>
</style>